import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.0.0-rc.0_@babel+core@7.24.7_react-dom@19.0.0-rc-6f23540c7d-20_qfm6gjlqelsmnknizk7uzuh4ei/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@15.0.0-rc.0_@babel+core@7.24.7_react-dom@19.0.0-rc-6f23540_qdb57yofxatx55vgauyzfa6pai/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.24.7_react-dom@19.0.0-rc-6f23540c7d-20240528_react@19.0.0-rc-6_37u3gk7qe5eiavh2ve2nam5afq/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/hero-landscape.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/hero-square.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lang]/globals.css");
